<template>
    <el-dialog :title="authorTitle"  :visible.sync="visible">
      <div class="author-container">
         <div>
            <Qrcode  :value="authorizationUrl" :options="qrOptions"  class="author-img"></Qrcode>
          </div>
          <div class="qr-tips">
              打开微信，扫一扫
          </div>
      </div>
      <div slot="footer">
        <el-button type="primary"  @click="refreshQr"  :disabled="refreshBtnDisabled">刷新二维码</el-button>
      </div>
    </el-dialog>
</template>
<script>

  import Qrcode from "@xkeshi/vue-qrcode";
  import {
    authorize,
    getWxState
  } from "@/request/api"; //这里是引入请求
 

  export default {
    data() {
      return {
        visible: false,
        authorTitle: "微信授权",
        authorizationUrl:"Data is not loaded. Please try again later",
        qrOptions: {
          size: 320
        },
        userId: null,
        checkTimeval: null,
        refreshBtnDisabled: true,
      };
    },
    components: {
      Qrcode,
      
    },
    methods: {
      initAuthorQr(userId) {
        this.visible = true;
        this.userId = userId;
        if(this.userId){
           this.authorTitle = "绑定微信";
        }else{
           this.authorTitle = "扫码登录";
        }

        this.refreshBtnDisabled = true;
        let authorResult = authorize({
          userId: this.userId
        }).then((data) => {
            this.refreshBtnDisabled = false;
            //检查结果
              this.authorizationUrl = data.authorizationUrl;
              this.checkResult(data.authorizeId);
        });

        return authorResult;


      },
      refreshQr(){
        //刷新
        let authorResult = this.initAuthorQr(this.userId);
        authorResult.then(() => {
          this.$message({
            message:"二维码已刷新",
            type:"success"
          });
        });

      },
      checkResult(authorizeId){
        clearInterval(this.checkTimeval);
        this.checkTimeval = setInterval(() => {
          getWxState({authorizeId:authorizeId}).then((data) => {
              if(data.authorized == 1){
                clearInterval(this.checkTimeval);
                if(data.token){
                  this.$cookies.set('token', data.token)
                  this.$message({
                    message: "登录成功，马上跳转至个人中心",
                    type: "success",
                    duration: "500"
                  });

                  if(this.$route.path == "/personalCenter"){
                    location.reload(true);
                  }else{
                    this.$router.push({
                      path: "/personalCenter"
                    });
                  }
                  
                }else{
                  this.$message({
                    message: "微信绑定成功",
                    type: "success"
                  });
                  
                  if(this.$route.path == "/personalCenter"){
                    location.reload(true);
                  }else{
                    this.$router.push({
                      path: "/personalCenter"
                    });
                  }

                }
              }else{
                console.log("等待微信用户授权：" + authorizeId , data);
              }

          }).catch(() => {
            clearInterval(this.checkTimeval);
          });
        }, 1000);
        
      }
    },
    watch: {
      visible: function(value, oldValue){
          if(!value){
              clearInterval(this.checkTimeval);
              console.log("用户已取消扫码");
          }else{
              console.log("等待用户扫码");
          }
      }
    }
  };
</script>

<style lang="less" scoped>
  .author-container{
    padding:20px;
    text-align: center;
  }

  .author-img{
    margin:auto;
  }

  .qr-tips{
    font-size:12px;
    padding:0px;
    text-align:left;
  }

</style>