<template>
  <div class="banner">
    <bordure-row title="个人中心" shadow style="margin-bottom:30px;">
      <auto-layout>
        <div class="personalTop">
          <div class="personalTop_main_title">账户信息</div>
          <div class="personalTop_main">
            <div class="info_header" @click="changeWeixin">
              <img src="../../assets/imgs/info_header.png" alt="" v-if="!memberEntity.memberImg">
              <img :src="memberEntity.memberImg" alt="" v-if="memberEntity.memberImg">
            </div>
            <div class="info-line">
              <!-- <img src="../../assets/imgs/personalPhone.png" alt="" /> -->
              <span class="personalTopTitle">登录账号:</span>
              <span class="personalTopContent">{{ memberEntity.memberPhone }}</span>
            </div>

            <div class="info-line">
              <!-- <img src="../../assets/imgs/personalIntegral.png" alt="" /> -->
              <span class="personalTopTitle">充值积分:</span>
              <span class="personalTopContent">{{ memberEntity.accountIntegral || 0}}分</span>
            </div>
            <div class="info-line">
              <!-- <img src="../../assets/imgs/personalIntegral.png" alt="" /> -->
              <span class="personalTopTitle">赠送积分:</span>
              <span class="personalTopContent">
                <span style="margin-right:20px;color:#e6a23c;">
                  <span>{{ memberEntity.presentIntegral || 0}}分</span>
                  <el-tooltip content="平台赠送的积分" >
                    <i class="el-icon-question"  style="color:#409eff;margin-left:5px;cursor:pointer;"></i>
                  </el-tooltip>
                </span>
                
              </span>
            </div>
            <!-- <div class="info-line">
              <span class="personalTopTitle">绑定微信:</span>
              <span class="personalTopContent">
                <span style="margin-right:20px;">{{memberEntity.wxName ? memberEntity.wxName : "-"}}</span>

                <span class="to-link-help" @click="changeWeixin">{{memberEntity.openid ? "更换绑定" : "点击绑定"}}</span>
              </span>
            </div> -->

            <div style="text-align: center;">
              <!-- <el-button  class="acc-btn" type="primary" size="small" @click.native="rechargeHandle()">积分充值
              </el-button> -->
              <el-button class="acc-btn"  size="small" @click="suggestPopup = true">我要反馈</el-button>
              <el-button class="acc-btn"  size="small" @click="changePW">修改密码</el-button>
            </div>
          </div>
        </div>


        <div class="share_content">
          <div class="share_main_title">邀请信息</div>
          <el-form :model="shareObj" ref="shareForm" :inline="false" size="normal">
            <div class="share_box">
              <div class="share_right">
                <el-form-item>
                  <p slot="label" class="share_right_title">当前已邀请好友</p>
                  <p class="share_right_num" style="cursor: pointer;" @click="getInviteMember()"><span
                      style="font-size:18px;text-decoration-line: underline;color: #409eff;">{{shareObj.inviteCount}}</span>
                    人</p>
                </el-form-item>
                <!-- <el-form-item>
                  <p slot="label" class="share_right_title">付费充值人数</p>
                  <p class="share_right_num" style="cursor: pointer;" @click="getgetMemberRewardList()"><span
                      style="font-size:18px;text-decoration-line: underline;color: #409eff;">{{shareObj.payCount}}</span>
                    人</p>
                </el-form-item> -->
              </div>
              <div class="share_left">
                <el-form-item label="推广链接" label-width="80px">
                  <el-input v-model="shareObj.shref" size="samll" :disabled="true">
                    <el-button slot="append" size="samll" @click="copyWay(shareObj.shref)">复制</el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="邀请码" label-width="80px" style="margin-bottom:0;">
                  <el-input v-model="shareObj.code" placeholder="" size="samll" :disabled="true">
                    <el-button slot="append" size="samll" @click="copyWay(shareObj.code)">复制</el-button>
                  </el-input>
                </el-form-item>
                <InviteTool></InviteTool>
              </div>
            </div>
          </el-form>
          <div class="share_extension">
          </div>

        </div>


        <div class="qrCode_box">
          <p class="qrCode_title"><b>专业的客服团队</b></p>
          <p class="qrCode_tip">扫一扫，有问题随时找客服解决</p>
          <div class="qrCode_content">
            <div class="qrCode">
              <div class="qrCode_img">
                <img :src="serviceQr" alt="">
              </div>
              <p>请扫码添加客服</p>
            </div>
            <div class="qrCode_txt">
              <p>1.不定期免费分享电商干货</p>
              <p>2.有任何问题可以随时咨询客服</p>
              <p>3.新注册联系客服获取积分</p>
            </div>
          </div>
        </div>

      </auto-layout>





    </bordure-row>





    <bordure-row title="使用记录" shadow style="margin-bottom:30px;">
      <el-tabs v-model="tabActiveName" @tab-click="alterTab('2')">
          <el-tab-pane v-for="item in tabPane" :label="item.name" :name="item.id" :key="item.id">
            <el-table 
            :data="tableData.list" 
            border style="width: 100%" size="mini" 
            :header-cell-style="{
                color: '#666666 !important',
                'letter-spacing': '0',
                'text-align': 'center',
                'font-weight': 'normal',
              }"
              v-loading="consumeDataLoading"
              >
              <el-table-column label="序号" width="90" header-align="center" align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{(tableData.currPage - 1) * pagesize + scope.$index + 1}}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="serialNum" label="平台流水" width="218" header-align="center" align="center">
              </el-table-column>
              <el-table-column prop="changeRemark" label="使用明细" width="240" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-button @click="toDetailRoute(scope.row)" v-if="scope.row.sourceSerialNum != null ">
                    {{scope.row.changeRemark}}</el-button>
                  <div v-if="scope.row.sourceSerialNum == null ">{{scope.row.changeRemark}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="changePoint" label="积分消耗" header-align="center" align="center">
                <template slot-scope="scope">
                  <span style="color: #f70000">{{
                    scope.row.changePoint
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="memberAccountIntegral" label="剩余积分" header-align="center" align="center">
                <template slot-scope="scope">
                  <span style="color: #000">{{
                    scope.row.memberAccountIntegral
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column prop="createTime" width="250" label="操作时间" header-align="center" align="center">
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!-- 分页器 -->
        <div style="display: flex; justify-content: flex-end">
          <el-pagination style="margin: 20px 0 37px" @size-change="handleSizeChange($event,'2')"
            @current-change="handleCurrentChange($event,'2')" :current-page="tableData.currPage"
            :page-sizes="[5, 10, 20, 50, 100]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.totalCount">
          </el-pagination>
        </div>
    </bordure-row>


    <bordure-row title="充值记录" shadow style="margin-bottom:30px;">
      <!-- 充值记录 -->
      <el-table :data="
            chargeTableData.list" border style="width: 100%" size="mini" :header-cell-style="{
            color: '#666666 !important',
            'letter-spacing': '0',
            'text-align': 'center',
            'font-weight': 'normal',
          }"
          v-loading="rechargeDataLoading"
          >
          <el-table-column label="序号" width="90" header-align="center" align="center">
            <template slot-scope="scope">
              <div>
                <span>{{(chargeTableData.currPage - 1) * pagesize2 + scope.$index + 1}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="serialNum" label="平台流水" width="218" header-align="center" align="center">
          </el-table-column>
          <el-table-column prop="changeRemark" label="充值明细" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button @click="viewOrderDetail(scope.row)" v-if="scope.row.sourceSerialNum != null " size="small">
                {{scope.row.changeRemark}}</el-button>
              <div v-if="scope.row.sourceSerialNum == null ">{{scope.row.changeRemark}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="changePoint" label="充值积分" header-align="center" align="center">
          </el-table-column>
          <el-table-column prop="createTime" width="250" label="订单充值时间" header-align="center" align="center">
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <div style="display: flex; justify-content: flex-end">
          <el-pagination style="margin: 20px 0 37px" @size-change="handleSizeChange2($event,'1')"
            @current-change="handleCurrentChange2($event,'1')" :current-page="chargeTableData.currPage"
            :page-sizes="[5, 10, 20, 50, 100]" :page-size="pagesize2" layout="total, sizes, prev, pager, next, jumper"
            :total="chargeTableData.totalCount">
          </el-pagination>
        </div>
    </bordure-row>


    <el-dialog title="邀请好友列表" :visible.sync="invitePop" width="800px">
      <el-table :data="inviteList.list" stripe>
        <el-table-column prop="memberPhone" label="手机号[被推荐人]"></el-table-column>
        <el-table-column prop="upMemberName" label="推荐人"></el-table-column>
        <el-table-column label="直属">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.upMemberName == memberEntity.memberName" style="color:red;">
                <el-tag type="success" size="mini">直接</el-tag>
              </span>
              <span v-else style="color:#409eff;">
                <el-tag type="info" size="mini">间接</el-tag>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="registerTime" label="注册时间"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div style="display: flex; justify-content: flex-end">
        <el-pagination style="margin: 20px 0 37px" @size-change="inviteListSizeChange($event)"
          @current-change="inviteListCurrentChange($event)" :current-page="inviteList.page"
          :page-sizes="[5, 10, 20, 50, 100]" :page-size="inviteFormData.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="inviteList.totalCount">
        </el-pagination>
      </div>
    </el-dialog>


    <el-dialog title="付费充值人数" :visible.sync="memberRewardPop" width="800px">
      <el-table :data="memberRewardList.list" style="width: 100%" size="mini">
        <el-table-column prop="refMemberChangeName" label="被推荐人"></el-table-column>
        <el-table-column prop="createTime" label="充值时间"></el-table-column>
        <el-table-column prop="rechargeAmount" label="支付金额"></el-table-column>
        <el-table-column prop="rebateRate" label="提成比例">
          <template slot-scope="scope">
            <span>{{scope.row.rebateRate*100}}%</span>
          </template>
        </el-table-column>
        <el-table-column prop="awardAmount" label="提成金额(综合税后)"></el-table-column>
      </el-table>
      <div style="display: flex; justify-content: flex-end">
        <el-pagination style="margin: 20px 0 37px" @size-change="memberRewardSizeChange($event)"
          @current-change="memberRewardCurrentChange($event)" :current-page="memberRewardList.page"
          :page-sizes="[10, 20, 50, 100]" :page-size="memberRewardFormData.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="memberRewardList.totalCount">
        </el-pagination>
      </div>
    </el-dialog>







    <!-- 建议弹窗 -->
    <suggest :pupopBus="suggestPopup" @suggestBus="suggeastClose"></suggest>


    <recharge v-if="rechargeVisible" ref="recharge" @freshData="freshData"></recharge>

    <order-pay-detail ref="orderPayDetail"></order-pay-detail>

    <weixin-author ref="weixinAuthor"></weixin-author>
  </div>
</template>
<script>
  import {
    getPointChangeLog,
    getInterfaceCategoryList,
    viewOrderPay,
    queryInviteMember,
    memberReward
  } from "@/request/api"; //这里是引入请求
  import Recharge from "../public/recharge.vue";

  import OrderPayDetail from "./OrderPayDetail.vue";
  import WeixinAuthor from "./weixinAuthor.vue";
  
  import suggest from "@/components/public/suggest.vue"

  import InviteTool from "@/components/public/InviteTool.vue";


  export default {
    data() {
      return {
        tabPane: [],
        tabActiveName: "-1",
        tableData: [], //这是消费记录table
        chargeTableData: {}, //这是充值记录table
        currentPage: 1, //初始页
        pagesize: 10, //    每页的数据
        currentPage2: 1, //初始页
        pagesize2: 10, //    每页的数据
        rechargeVisible: false, //是否显示充值pup
        invitePop: false,
        inviteList: {},
        inviteFormData: {
          page: 1,
          limit: 10,
        },
        memberRewardPop: false,
        memberRewardList: {
          list: []
        },
        memberRewardFormData: {
          page: 1,
          limit: 10,
        },
        suggestPopup: false,
        consumeDataLoading: false,
        rechargeDataLoading: false,
      };
    },
    components: {
      Recharge,
      OrderPayDetail,
      WeixinAuthor,
      suggest,
      InviteTool
    },
    mounted() {
        this.initShareInfo();
    },
    methods: {
      // size-change和current-change事件来处理页码大小和当前页变动时候触发的事件。
      handleSizeChange(size, type) {
        this.currentPage = 1; //点击第几页
        this.pagesize = size; //每页下拉显示数据
        this.getTableList(type)

      },
      handleCurrentChange(currentPage, type) {
        this.currentPage = currentPage; //点击第几页
        this.getTableList(type)
      },
      // size-change和current-change事件来处理页码大小和当前页变动时候触发的事件。
      handleSizeChange2(size, type) {
        this.currentPage2 = 1; //点击第几页
        this.pagesize2 = size; //每页下拉显示数据
        this.getTableList(type)
      },
      handleCurrentChange2(currentPage, type) {
        this.currentPage2 = currentPage; //点击第几页
        this.getTableList(type)

      },
      initShareInfo(){
        this.getTabList();
        this.alterTab(2);
        this.alterTab(1);

        this.$store.dispatch("getMemberInfo");
      },
       // 获取tab
      getTabList() {
        getInterfaceCategoryList().then(data => {
          data.data.forEach((item) => {
            this.tabPane.push({
              name:item.name,
              id:item.apiCategoryId + ""
            })
          })
          this.tabPane.unshift({name:"全部",id: "-1"});
        })
      },
      // 改变tab
      alterTab(changeType) {
        this.currentPage = 1;
        // console.log(val, changeType);
        this.getTableList(changeType)
      },
      
      getTableList(changeType) {
        if (changeType == 2) {
          this.consumeDataLoading = true;
          getPointChangeLog({
            limit: this.pagesize,
            page: this.currentPage,
            categoryId: this.tabActiveName,
            changeType: changeType,
          }).then((data) => {
            //sourceType1、商品透视2、深度验号3、自动计划表4、单品排名,,,changeType:1增加，2消费
            if (data.data != undefined) {
              this.$set((this.tableData = data.data));
            }
          }).finally(()=>{
            this.consumeDataLoading = false;
          });
        } else {
          this.rechargeDataLoading = true;
          getPointChangeLog({
            limit: this.pagesize2,
            page: this.currentPage2,
            changeType: changeType,
          }).then((data) => {
            this.chargeTableData = data.data || {};
            //sourceType1、商品透视2、深度验号3、自动计划表4、单品排名,,,changeType:1增加，2消费
          }).finally(() => {
            this.rechargeDataLoading = false;
          });
        }
      },


      // 充值
      rechargeHandle() {
        this.rechargeVisible = true;
        this.$nextTick(() => {
          this.$refs.recharge.init();
        });
      },

      // 复制
      copyWay(val) {
        this.$comm.copy(val + '\n' + this.$store.state.copySuffix)
      },

      // 修改密码
      changePW() {
        this.$store.commit("alterChangePWFlag", false);
        this.$store.commit("alterLoginFlag", true);
      },
      freshData() {
        this.alterTab(2);
        this.alterTab(1);
      },

      toDetailRoute(row) {
        this.$router.push({
          path: "/manage/taskNew",
          query: {
            orderNo: row.sourceSerialNum
          }
        });
      },
      viewOrderDetail(row) {
        if (row.sourceType == 5) {
          this.$router.push({
            path: "/member/order",
            query: {
              orderNo: row.sourceSerialNum
            }
          });
        } else if (row.sourceType == 6) {
          this.$router.push({
            path: "/manage/taskNew",
            query: {
              orderNo: row.sourceSerialNum
            }
          });
        } else {
          //查询订单信息
          viewOrderPay({
            orderNo: row.sourceSerialNum
          }).then((data) => {
            this.$refs.orderPayDetail.init(data.data);
          });

        }
      },

      // 获取邀请成员记录
      getInviteMember() {
        this.invitePop = true
        this.transitInviteMember()
      },

      transitInviteMember() {
        queryInviteMember(this.inviteFormData).then((data) => {
          this.inviteList = data.data
        })
      },

      inviteListSizeChange(e) {
        this.inviteFormData.limit = e
        this.transitInviteMember()
      },
      inviteListCurrentChange(e) {
        this.inviteFormData.page = e
        this.transitInviteMember()
      },



      // 付费充值人数
      getgetMemberRewardList() {
        this.memberRewardPop = true
        this.getMemberReward()
      },
      getMemberReward() {
        memberReward(this.memberRewardFormData).then(data => {
          this.memberRewardList = data.page
        })
      },


      memberRewardSizeChange(e) {
        this.memberRewardList.limit = e
        this.getMemberReward()
      },
      memberRewardCurrentChange(e) {
        this.memberRewardList.page = e
        this.getMemberReward()
      },

      changeWeixin() {
        //绑定微信
        //this.$refs.weixinAuthor.initAuthorQr(this.memberEntity.userId);
      },
      // 关闭建议
      suggeastClose(bool) {
        this.suggestPopup = bool
      },
    },
    computed:{
      memberEntity(){
        let memberInfo = this.$store.state.memberInfo;
        if(memberInfo){
          return memberInfo.memberEntity;
        }
        return {};
      },
      serviceQr(){
        return this.$store.state.serviceQr || "";
      },
      shareObj(){
        return this.$store.state.shareObj || {};
      }
    }
  };
</script>

<style lang="less" scoped>
  .personalTop {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    width: 25%;
    overflow: hidden;

    .personalTop_main_title {
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      font-size: 14px;
      border-bottom: 1px solid #ebeef5;
    }

    .personalTop_main {
      padding: 20px;

      .info_header {
        width: 72px;
        height: 72px;
        margin: 0 auto 24px;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }

      .info-line {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }

      .personalTopTitle {
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
      }

      div>img {
        width: 24px;
        height: 24px;
        display: block;
        margin-right: 6px;
      }

      .personalTopContent {
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        margin-left: 30px;
      }

    }
  }

  .tableBanner {
    margin: 0 auto;
    background-color: #fff;

    .tableTitle {
      margin-bottom: 20px;
    }

    /deep/.el-tabs__nav-wrap::after {
      background: #fff;
    }

    /deep/.el-tabs__nav-scroll {
      background-color: #f8f8f8;
    }

    /deep/.el-tabs__item {
      padding: 0 20px !important;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0;
    }

    /deep/.el-tabs__active-bar {
      padding: 0 20px;
      background-color: #5d7cff;
      left: -20px;
      top: 0;
    }

    /deep/.is-active {
      color: #5d7cff;
      background-image: linear-gradient(180deg, #f5f7ff 0%, #ffffff 100%);
    }

    /deep/.el-tabs__item:hover {
      color: #5d7cff;
    }
  }


  .share_content {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    width: 40%;
    overflow: hidden;

    .share_main_title {
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      font-size: 14px;
      border-bottom: 1px solid #ebeef5;
    }
  }

  .share_box {
    // display: flex;
    padding: 20px;
  }

  .share_left {
    padding-top: 0px;
  }

  .share_right {
    display: flex;
    text-align: center;
    justify-content: space-around;

    .share_right_title {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .share_right_num {
      font-size: 16px;
      text-align: center;
    }
  }

  .qrCode_box {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    padding: 20px;
    background: rgb(122, 133, 250);
    color: #fff;
    width: 30%;
    box-sizing: border-box;
    overflow: auto;

    .qrCode_title {
      text-align: center;
      font-size: 24px;
    }

    .qrCode_tip {
      text-align: center;
      font-size: 16px;
      padding: 6px;
    }

    .qrCode_content {
      display: flex;
      margin-top: 10px;

      .qrCode {

        .qrCode_img {
          width: 135px;
          height: 135px;
          background: linear-gradient(0deg, #ceefff, #7bd5ff);
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        p {
          font-size: 14px;
          text-align: center;
          margin-top: 10px;
        }
      }

      .qrCode_txt {
        font-size: 16px;
        margin-left: 10px;

        p {
          margin-bottom: 25px;
        }
      }
    }


  }

  .to-link-help {
    color: #5d7cff;
    cursor: pointer;
  }

  .share_extension{
    padding: 0 20px 0 100px;
  }

  .acc-btn{
    margin-bottom:10px;
  }

  
  @media screen and (max-width: 640px) {

    .personalTop,
    .share_content,
    .qrCode_box {
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
</style>