<template>
  <div class="tool_invite">
    <p v-for="(n, i) in inviteRegisterList" :key="i">
      <span v-html="formatDetail(n.shortName)"></span>
    </p>
    <!-- <p style="margin-top:10px;font-size:10px;text-shadow:none;">*非特别说明，邀请获得的积分均为普通功能积分，不可用于礼品单消耗，请知悉</p> -->
  </div>
</template>

<script>

export default {
  props: [],
  data() {
    return {
      
    };
  },
  components: {
  },
  mounted() {
 
    this.$store.dispatch("getInviteRegisterList");
  },
  methods: {
    formatDetail(detail) {
      if (detail) {
        detail = detail.replace(/(\s*\d+\s*)(人)/g, function (p0, p1, p2) {
          return " <b >" + p1 + "</b> " + p2;
        });

        detail = detail.replace(
          /(\s*\d+(\.\d+)?\s*)((万|充值)?积分)/g,
          function (p0, p1, p2, p3) {
            return (
              " <span style='color:#dd3333;'>" + p1 + "</span> " + p3
            );
          }
        );
      }

      return detail;
    },

  },
  computed: {
    inviteRegisterList(){
      return this.$store.state.inviteRegisterList || [];
    }
  },
  watch: {
   
  },
};
</script>

<style lang="less" scoped>

.tool_invite {
  text-align: left;
  font-size: 14px;
  padding: 10px;
  margin-top:10px;
  border-top:solid 1px #eee;
}

</style>