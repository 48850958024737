<template>

    <el-dialog title="支付订单详情"  :visible.sync="visible">
        <el-row class="order-line">
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">订单编号</label>
                    <span  class="order-value">{{orderEntity.orderNo}}</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">支付类型</label>
                    <span  class="order-value">
                         <el-tag v-if="orderEntity.payType == 1 " type="success">微信支付</el-tag>
                        <el-tag v-else type="warning">支付宝支付</el-tag>

                    </span>
                </div>
            </el-col>
        </el-row>
        <el-row class="order-line">
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">支付状态</label>
                    <span  class="order-value">
                        <el-tag v-if="orderEntity.payStatus == 1" type="danger">未支付</el-tag>
                        <el-tag v-else>已支付</el-tag>
                    </span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">商品名称</label>
                    <span  class="order-value">{{orderEntity.goodsName}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="order-line">
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">支付人名称</label>
                    <span  class="order-value">{{orderEntity.memberName}}</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">支付金额</label>
                    <span  class="order-value">{{orderEntity.paymentAmount}}</span>
                </div>
            </el-col>
        </el-row>
        <el-row class="order-line">
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">支付时间</label>
                    <span  class="order-value">{{orderEntity.payTime}}</span>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="order-item">
                    <label  class="order-label">创建时间</label>
                    <span  class="order-value">{{orderEntity.createTime}}</span>
                </div>
            </el-col>
        </el-row>

    </el-dialog>

</template>


<script>


export default {
    data(){
        return {
            visible: false,
            orderEntity: {}
        }
        
    },

    methods: {
        init(orderEntity) {
            this.visible = true;
            this.orderEntity = orderEntity;
        }

    }

}

</script>


<style lang="less" scoped>

    .order-line{
        line-height:2;
        display:flex;
        border-bottom:solid 1px #eee;
        padding:10px;

        .order-item{
            display:flex;
        }

        .order-label{
            width:120px;
            color:#666;
        }

        .order-label:after{
            content:":";
        }


        .order-value{
            color:#333;
        }
        
    }

    

</style>